<template>
    <div class="invite-wrap">
        <section class="section">
            <div class="logo-wrap">
                <img src="~assets/logo_icon.png">
                <img class="text" src="~assets/logo_text_black.png">
            </div>
            <div class="bkg-wrap">
                <img class="image-center" src="~assets/invite_bkg.png">
            </div>
            <div class="inviter">{{ text1 }}</div>
            <div class="company">{{ text2 }}</div>
            <div class="btn pointer" @click="handleJoin">{{ text3 }}</div>
            <template v-if="show">
                <div class="switch-account pointer" @click="handleChangeAccount">{{ $t('layout.switchAccount') }}</div>
                <div class="current-account">{{ $t('pages.login.currentAccount') }}：{{ login_user_account }}</div>
            </template>
        </section>
    </div>  
</template>
<script>
    import { login } from '@/mixins/index'

    export default {
        name: "",
        mixins: [login],
        components: {},
        props: [],
        data () {
            return {
                text1: '',
                text2: '',
                text3: '',
                is_login: false,
                is_in_organization: false,
                show: false,
                login_user_account: '',
                invite_user_code: '',
                state: '',
            }
        },
        computed: {},
        methods: {
            handleJoin() {
                if  (this.state ===  'login') {
                    //
                    this.$router.push({
                        path: '/login',
                        query: {
                            invite_user_code: this.invite_user_code
                        }
                    })
                } else if (this.state === 'join') {
                    //接受，并进去企业
                    //重置路由。以解决在个人登录状态下，直接替换网址时，chrome、firefox下vuex数据还在。safarixia数据被清除问题
                    this.$store.dispatch('RESET_ROUTES')
                    this.$api.post('organizations/current/users', { invite_user_code: this.invite_user_code }).then(({ receive_org_id, receive_org_name }) => {
                        this.isRemember = true
                        this.goToCompany(receive_org_id, receive_org_name)
                        this.$Message.success(this.$t('pages.login.joined'))
                    })
                } else if (this.state == 'enter') {
                    this.$store.dispatch('RESET_ROUTES')
                    this.isRemember = false
                    this.goToCompany(this.receive_org_id, this.receive_org_name)
                } else if (this.state === 'home') {
                    //失效或过期==>返回首页
                    let token = localStorage.getItem('token')
                    if (!token) {
                        this.$router.push({
                            path: '/login'
                        })
                    } else {
                        let is_org = this.$store.state.default_bucket_tokens.org
                        if (is_org) {
                            //登录企业
                            this.$router.push({ name: 'companySpaces' })
                        } else {
                            //个人登录
                            this.$router.push({ name: 'personSpaces' })
                        }
                    }
                }
            },
            handleChangeAccount() {
                //切换账号，清除账号登录信息
                this.$store.dispatch('Logout')
                //跳转登录页
                this.$router.push({
                    path: '/login',
                    query: {
                        invite_user_code: this.invite_user_code
                    }
                })
            }
        },
        mounted() {
            this.invite_user_code = this.$route.query.invite_user_code
            let params = {
                invite_user_code: this.invite_user_code
            }
            this.$api.get('organizations/current/users/check_invite_user_code', { params }).then(({ is_login, is_in_organization, receive_org_name, launch_user_name, login_user_account, receive_org_id }) => {
                this.is_login = is_login
                this.is_in_organization = is_in_organization
                this.login_user_account = login_user_account
                this.receive_org_id = receive_org_id
                this.receive_org_name = receive_org_name
                //未登录&&未加入
                if (!is_login && !is_in_organization) {
                    this.text1 = `${launch_user_name}${this.$t('pages.login.invited')}`
                    this.text2 = receive_org_name
                    this.text3 = this.$t('pages.login.loginAndiJoin')
                    this.state = 'login'
                }
                //已登录&&未加入
                if (is_login && !is_in_organization) {
                    this.text1 = `${launch_user_name}${this.$t('pages.login.invited')}`
                    this.text2 = receive_org_name
                    this.text3 = this.$t('pages.login.joinNow')
                    this.show = true
                    this.state = 'join'
                }
                //已登录&&已加入
                if (is_login && is_in_organization) {
                    this.text1 = this.$t('pages.login.joined2')
                    this.text2 = receive_org_name
                    this.text3 = this.$t('pages.login.enterCompany')
                    this.show = true
                    this.state = 'enter'
                }
            }).catch(({ data }) => {
                this.text2 = data.message
                this.text3 = this.$t('pages.login.backHome')
                this.state = 'home'
            })
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";

.invite-wrap {
    height: 100%;
    .section {
        display: inline-block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .logo-wrap {
            margin-bottom: 21px;
            height: 63px;
            text-align: center;
            img {
                display: inline-block;
            }
            .text {
                margin-top: 9px;
                vertical-align: top;
            }
        }
        .bkg-wrap {
            position: relative;
            width: 386px;
            height: 250px;
        }
        .inviter {
            margin: 31px 0 15px;
            height: 14px;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #afb4ba;
        }
        .company {
            margin-bottom: 50px;
            height: 29px;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #092c4c;
        }
        .btn {
            margin: 0 auto;
            width: 260px;
            height: 48px;
            background: @primary-color;
            border-radius: 4px;
            text-align: center;
            font-weight: 700;
            font-size: 19px;
            line-height: 48px;
            color: #fff;
        }
        .switch-account {
            margin: 37px auto 8px;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-decoration: underline;
            color: #000;
            text-align: center;
        }
        .current-account {
            height: 18px;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #afb4ba;
        }
    }
}
</style>